@import "Main.scss";

.AboutMe {
  margin: 0;
  position: relative;

  z-index: -1;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $large-card-padding;

  .Card {
    width: $large-card-width;
    padding: 20px;
    border-radius: 30px;
    background-color: $card-background;
    gap: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }

  .DescriptionDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    h2 {
      text-align: center;
      font-size: var(--fs-medium);
      color: $main-text;
    }

    p {
      text-align: center;
      color: $light-grey;
    }

    span {
      text-align: center;
    }
  }

  #about-me-image {
    width: 50%;
  }

  #wakatime-graph {
    width: 90%;
  }

  .Wakatime {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
      align-self: center;
      font-size: var(--fs-small);
      font-style: italic;
      white-space: nowrap;
      color: $light-grey;
    }

    img {
      width: 100%;
      align-self: center;
      text-align: center;
    }
  }

  .Description {
    font-weight: normal;
    font-size: var(--fs-small);
  }
}
