@import "Main.scss";

$button-border-radius: 10px;

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 25%);

  h2 {
    font-style: italic;
    font-weight: bolder;
    text-align: center;
    font-size: var(--fs-h2-title);
    color: $main-text;

    span {
      font-size: var(--fs-h2-title);
      background: linear-gradient(to bottom right, $gradient-start, $gradient-end);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    font-weight: bold;
    text-align: center;
    font-size: var(--fs-small);
    color: $light-grey;
  }

  .Buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }

    button {
      border: none;
      cursor: pointer;
      display: inline-block;
      border-radius: $button-border-radius;
      outline: none;
      padding: 10px 20px;
      position: relative;

      span {
        position: relative;
        z-index: 2;
        color: $main-text;
        text-decoration: none;
        font-size: var(--fs-small);

        display: flex;
        align-content: center;
        justify-content: center;
      }

      background: linear-gradient(to right, $button-start, $button-end);

      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: $button-hover;
        transition: all 0.35s;
        border-radius: $button-border-radius;
      }

      &:hover {
        color: white;

        &:after {
          width: 100%;
        }
      }

      transition: all 0.35s;
    }
  }
}

