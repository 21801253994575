$breakpoints: (
  small: 400px,
  medium: 450px,
  large: 900px
);

@mixin mq($key) {
  $size: map-get($breakpoints, $key);

  @media only screen and (min-width: $size) {
    @content;
  }
}
