@use "type-scale" as *;
@use "media-query" as *;

html {
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}

$light-grey: #D3D3D3;
$gradient-start: #FF6A00;
$gradient-end: #EE0979;
$main-text: #FFFFFF;
$hover-text: #9518FC;

$button-start: #EECDA3;
$button-end: #EF629F;
$button-hover: #FF1EAD;

$midground-opacity: 75%;
$card-background: rgba(0, 0, 0, 35%);
$pastel-red: #F8858B;

$small-phone: 400px;
$normal-phone: 850px;
$tablet: 1000px;

$large-card-width: 80vw;
$large-card-padding: 2vw;

* {
  font-family: Roboto, sans-serif;
  font-size: 2rem;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  @each $screen-size, $font-size in $type-scale {
    @if $screen-size == small {
      @each $size, $value in $font-size {
        --fs-#{$size}: #{$value};
      }
    } @else if $screen-size == medium {
      @include mq(medium) {
        @each $size, $value in $font-size {
          --fs-#{$size}: #{$value};
        }
      }
    } @else {
      @include mq(large) {
        @each $size, $value in $font-size {
          --fs-#{$size}: #{$value};
        }
      }
    }
  }
}

.Bold {
  font-weight: bold;
}

.Highlight {
  color: $pastel-red;
  font-style: italic;
}

.Emphasize {
  font-style: italic;
}

.Test {
  background-color: rgba(0,0,0,25%);
}
