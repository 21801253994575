@import "./Main.scss";

body {
  --start: #6A73A3;
  --end: #A049A3;
  background-image: linear-gradient(to bottom right, var(--start), var(--end));
  background-attachment: fixed;
}

.Component {
  padding-top: 30px;
  padding-bottom: 30px;
}
