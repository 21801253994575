@import "Main.scss";

$pastel-yellow: #FDFD96;
$pastel-blue-start: #92E8F1;
$pastel-blue-end: #32C0C5;

.CodingProjects {
  margin: 0;
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $large-card-padding;

  a:not(.IgnoreLink) {
    background: linear-gradient($pastel-blue-start, $pastel-blue-end);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:hover {
      background: linear-gradient($pastel-blue-end, $pastel-blue-start);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .Card {
    width: $large-card-width;
    padding: 20px;
    border-radius: 30px;
    background-color: $card-background;
    gap: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease;

    &:hover {
      scale: 105%;
    }

    .Left {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      h2 {
        font-size: var(--fs-large-medium);
        text-align: center;
        color: $main-text;
      }

      p {
        font-weight: normal;
        text-align: center;
        color: $light-grey;
        font-size: var(--fs-small);
      }

      .Frameworks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;

        h3 {
          font-size: var(--fs-small);
          color: $pastel-yellow;
          font-style: italic;
          text-overflow: fade;
        }

        p, span {
          text-align: center;
          font-size: var(--fs-very-small);
          font-weight: normal;
          a {
            font-size: var(--fs-very-small);
            font-weight: normal;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .Repository {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;

        outline: white solid 2px;

        div {
          display: flex;
          flex-direction: column;

          h3, p, a {
            text-align: center;
          }

          h3 {
            font-size: var(--fs-medium-small);
            font-style: italic;
            color: $main-text;
          }

          a {
            font-size: var(--fs-small);
            margin: 0;
            padding: 0;
            align-self: center;
          }

          p {
            font-weight: normal;
          }
        }
      }
    }

    .Right {
      width: 20%;

      img {
        width: 100%;
      }

      iframe {
        width: 100%;
        display: block;
        margin: auto;
      }

      #back-to-the-jungle-demo {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .Card {
      flex-direction: column;

      .Left {
        width: 100%;
      }

      .Right {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 50%;
        }


      }
    }
  }
}