@import "./Main.scss";

.Navbar {
  width: 100%;  // Take up whole horizontal space
  background-color: rgba(0, 0, 0, $midground-opacity);

  .Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 0.25vw 10vw;

    .HamburgerContainer {
      display: flex;
    }

    .ToggleMenu {
      position: absolute;
      width: 30px;
      height: 100%;
      z-index: 3;
      cursor: pointer;
      align-self: center;
      opacity: 0;
    }

    .Hamburger {
      position: relative;
      width: 30px;
      height: 4px;
      background: #FFF;
      border-radius: 10px;
      cursor: pointer;
      z-index: 2;
      transition: all 0.3s ease;

      &:before, &:after {
        content: "";
        position: absolute;
        height: 4px;
        right: 0;
        background: #FFF;
        border-radius: 10px;

        transition: all 0.3s ease;
      }

      &:before {
        top: -10px;
        width: 20px;
      }

      &:after {
        top: 10px;
        width: 25px;
      }
    }

    .Hamburger, .ToggleMenu {
      display: none;
    }

    input:checked ~ .Hamburger {
      background: transparent;
    }

    input:checked ~ .Hamburger:before {
      top: 0;
      transform: rotate(-45deg);
      width: 30px;
    }

    input:checked ~ .Hamburger:after {
      top: 0;
      transform: rotate(45deg);
      width: 30px;
    }

    input:checked ~ .Menu {
      right: 0;
      transition: all 0.3s ease;
    }

    @media (max-width:$tablet) {
      .Hamburger, .ToggleMenu {
        display: block;
      }

      .Menu {
        justify-content: start;
        flex-direction: column;
        align-items: center;
        position: fixed;
        background: rgba(0, 0, 0, 75%);
        top: 0;
        right: -250px;
        width: 250px;
        height: 100%;
        padding-top: 65px;
        z-index: 1;
        transition: all 0.3s ease;

        li {
          width: 100%;
          padding-left: 30px;

          a {
            padding: 30px;
          }
        }
      }
    }
  }

  .Logo {
    font-size: var(--fs-medium);
    text-decoration: none;
    font-style: italic;
    font-weight: bold;
    cursor: pointer;
    background-image: linear-gradient(to top, $gradient-start, $gradient-end);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .Menu {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 20px;
  }

  .MenuItem {

    button {
      border: none;
      background: none;
      font-size: var(--fs-small);
      text-decoration: none;
      color: $main-text;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    :hover {
      color: $hover-text;
      transition: all 0.3s ease;
    }

    &:hover:not(&.Selected) {
      transform: translateY(-5px);
      transition: all 0.3s ease;
    }

    &:not(&:hover) {
      transition: all 0.3s ease;
    }

    &.Selected {

      button {
        font-weight: bold;
      }

      border-bottom: 5px solid rgb(132, 0, 255);
      margin-bottom: 5px;
      transition: all 0.3s ease;
    }
  }
}
