@import "Main.scss";

.SocialMediaCards {
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin: 0;
  gap: 30px;

  .Card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 275px;
    height: 350px;

    @media screen and (max-width: $small-phone) {
      height: 250px;
    }

    border-radius: 30px;
    transition: all 0.3s ease;
    text-decoration: none;
    background-color: $card-background;

    h3 {
      color: $main-text;
      font-style: italic;
      transition: all 0.3s ease;
      text-align: center;
      font-size: var(--fs-large-medium);
    }

    img {
      width: 75%;
      filter: grayscale(75%);
      transition: all 0.3s ease;
      align-self: center;
    }

    &:hover {
      scale: 110%;
      border-radius: 50px;

      h3 {
        color: $pastel-red;
        transition: all 0.3s ease;
      }

      img {
        filter: none;
        transition: all 0.3s ease;
      }
    }
  }
}
